import React from 'react';

import { Positions, CareersHero, Benefits } from 'components/careers';
import seo from 'data/seo.json';
import { SEO } from 'components/ui';

export default function Careers() {
    return (
        <>
            <SEO
                title={seo.careersTitle}
                description={seo.careersDescription}
                ogTitle={seo.careersOgTitle}
                ogDescription={seo.careersOgDescription}
                ogImage={seo.careersOgImage}
            />
            <CareersHero />
            <Positions />
            <Benefits />
        </>
    );
}
