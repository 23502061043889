import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING, TABLET_GRID_SIZE } from 'consts';
import { BackgroundImage } from 'components/ui';

export const Container = styled.section`
    display: flex;
    width: 100%;
    justify-content: center;
`;

export const Details = styled.div`
    color: ${Colors.violet};
    width: 100%;
    justify-content: flex-start;
    margin-top: 12rem;
    margin-bottom: 40.8rem;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 12 + DESKTOP_GRID_SPACING * 11}rem;
    }

    @media ${MediaQueries.tablet} {
        padding: 0 7.4rem;
    }

    @media ${MediaQueries.phone} {
        text-align: center;
        padding: 0 2rem;
        margin-top: 6rem;
        margin-bottom: 12rem;
    }
`;

export const Title = styled.h1`
    margin-bottom: 4rem;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 5 + DESKTOP_GRID_SPACING * 4}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${(TABLET_GRID_SIZE / 4) * 3}rem;
    }

    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        line-height: 5rem;
        font-weight: 700;
        margin-bottom: 2rem;
    }
`;

export const Description = styled.h4`
    font-weight: normal;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 5 + DESKTOP_GRID_SPACING * 4}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${(TABLET_GRID_SIZE / 4) * 3}rem;
    }
`;

export const CareersBackgroundImage = styled(BackgroundImage)`
    div {
        height: 100%;
    }
`;
