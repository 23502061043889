import React from 'react';

import { positions } from 'data/careers.json';
import { useQueryContext } from 'hooks';

import {
    Card,
    CardsContainer,
    CardDescription,
    CardTitle,
    Container,
    Content,
    Cta,
    Description,
    Icon,
    Info,
    Link,
    Title
} from './Positions.style';

export function Positions() {
    const { jobs } = useQueryContext();
    const availableJobs = jobs.filter((job) => job.isAvailable);

    return (
        <Container id="jobs">
            <Content>
                <Title>{positions.title}</Title>
                <Description>{positions.description}</Description>
                <CardsContainer>
                    {availableJobs.map((item) => (
                        <Link to={item.path} key={item.id}>
                            <Card>
                                <Icon fluid={item.featuredImage.childImageSharp.fluid} />
                                <Info>
                                    <CardTitle>{item.title}</CardTitle>
                                    <CardDescription>{item.excerpt}</CardDescription>
                                    <Cta>Read more &nbsp;&gt;</Cta>
                                </Info>
                            </Card>
                        </Link>
                    ))}
                </CardsContainer>
            </Content>
        </Container>
    );
}
