import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';
import {
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SIZE,
    DESKTOP_GRID_SPACING,
    TABLET_GRID_SIZE
} from 'consts';

export const WhyContainer = styled.div`
    width: 100%;
    background-color: ${Colors.violet};
    color: ${Colors.white};
    display: flex;
    justify-content: center;
    margin-top: 9rem;
`;

export const WhySection = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
        padding: 12rem 0;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        padding: 12rem 0rem;
    }

    @media ${MediaQueries.phone} {
        padding: 6rem 2rem;
    }
`;

export const TitleWhy = styled.h2`
    text-align: center;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
        margin-bottom: 4rem;
    }

    @media ${MediaQueries.tablet} {
        margin-bottom: 4rem;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 2rem;
    }
`;

export const DescriptionWhy = styled.p`
    text-align: center;
    width: 100%;
    white-space: pre-line;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SPACING * 7}rem;
    }
`;

export const ListBenefits = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    color: ${Colors.violet};

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
        padding: 12rem 2rem 4rem 0rem;
        margin: 0 2rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        padding-top: 12rem;
        margin: 0 2rem;
    }

    @media ${MediaQueries.phone} {
        padding: 6rem 2rem 2rem 2rem;
    }
`;

export const TitleBenefits = styled.h3`
    padding-bottom: 2rem;

    @media ${MediaQueries.phone} {
        text-align: center;
    }
`;

export const DescriptionBenefits = styled.h4`
    line-height: 3.8rem;
    padding-bottom: 12rem;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 4}rem;
    }

    @media ${MediaQueries.tablet} {
        padding-bottom: 10rem;
    }

    @media ${MediaQueries.phone} {
        padding-bottom: 6rem;
        text-align: center;
    }
`;

export const ContainerBenefits = styled.div`
    @media ${MediaQueries.desktop} {
        display: grid;
        grid-gap: 8rem;
        grid-template-columns: 29% 29% 29%;
        grid-row-gap: 4rem;
    }

    @media ${MediaQueries.tablet} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        margin: 2rem 0 2rem 0;
    }
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media ${MediaQueries.tablet} {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 30rem;
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.phone} {
        text-align: center;
        align-items: center;
        margin-bottom: 4.5rem;
    }
`;

export const ItemTitle = styled.h4`
    padding-bottom: 0.5rem;
    padding-top: 2rem;
    line-height: 3.8rem;
`;

export const ItemSubtitle = styled.p`
    font-style: italic;
    padding-bottom: 2rem;
`;
