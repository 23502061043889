import React from 'react';

import content from 'data/careers.json';
import { useQueryContext } from 'hooks';

import {
    Container,
    Details,
    Title,
    Description,
    CareersBackgroundImage
} from './CareersHero.style';

export function CareersHero() {
    const {
        images: {
            careers: { hero: image }
        }
    } = useQueryContext();

    return (
        <Container>
            <CareersBackgroundImage
                mobile={image.mobile}
                tablet={image.tablet}
                desktop={image.desktop}
                alt={content.hero.alt}
            >
                <Container>
                    <Details>
                        <Title>{content.title}</Title>
                        <Description>{content.description}</Description>
                    </Details>
                </Container>
            </CareersBackgroundImage>
        </Container>
    );
}
