import { Link as DefaultLink } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import {
    DESKTOP_GRID_SIZE,
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SPACING,
    TABLET_GRID_SIZE
} from 'consts';

export const Link = styled(DefaultLink)`
    @media ${MediaQueries.phone} {
        width: 100%;
    }

    :nth-of-type(4n + 1) > div {
        background-color: ${Colors.orange};
    }

    :nth-of-type(4n + 2) > div {
        background-color: ${Colors.magenta};
    }

    :nth-of-type(4n + 3) > div {
        background-color: ${Colors.green};
    }

    :nth-of-type(4n + 4) > div {
        background-color: ${Colors.violet};
    }
`;

export const Container = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
`;

export const Content = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
        padding: 12rem 0;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        padding: 12rem 0rem;
    }

    @media ${MediaQueries.phone} {
        padding: 6rem 2rem;
    }
`;

export const Title = styled.h2`
    color: ${Colors.violet};
    text-align: center;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
        margin-bottom: 4rem;
    }

    @media ${MediaQueries.tablet} {
        margin-bottom: 4rem;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 2rem;
    }
`;

export const Description = styled.p`
    color: ${Colors.violet};
    text-align: center;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SPACING * 7}rem;
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.tablet} {
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 4rem;
    }
`;

export const CardsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 5rem;

    @media ${MediaQueries.phone} {
        flex-direction: column;
    }

    @media ${MediaQueries.desktop} {
        justify-content: space-between;
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 2.5rem;
    box-shadow: 0 4rem 6rem -2rem rgba(77, 52, 91, 0.3);
    color: ${Colors.white};
    padding: 4rem;
    margin-bottom: 4rem;

    @media ${MediaQueries.desktop} {
        width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
    }

    @media ${MediaQueries.phone} {
        flex-direction: column;
        padding: 2rem;
        padding-bottom: 4rem;
        margin-bottom: 2rem;
    }
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
`;

export const CardTitle = styled.h2`
    color: ${Colors.white};
    width: 100%;
    min-height: 11.4rem; // x2 related to line-height for h2

    @media ${MediaQueries.phone} {
        max-width: 29.5rem;
    }

    @media ${MediaQueries.tablet} {
        width: 36rem;
    }
`;

export const CardDescription = styled.p`
    color: ${Colors.white};
    margin-top: 2rem;
    max-width: 36rem;

    @media ${MediaQueries.phone} {
        width: 100%;
    }
`;

export const Cta = styled.p`
    color: ${Colors.white};
    font-size: 1.8rem;
    font-weight: 500;
    margin-top: 2rem;
`;

export const Icon = styled(Img)`
    height: 12rem;
    width: 12rem;

    @media ${MediaQueries.desktop} {
        margin-right: 2rem;
    }

    @media ${MediaQueries.tablet} {
        margin-right: 2rem;
    }
`;
