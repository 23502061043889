import React from 'react';

import { benefits } from 'data/careers.json';
import { useQueryContext } from 'hooks';
import { BackgroundImage } from 'components/ui';

import {
    WhySection,
    TitleWhy,
    DescriptionWhy,
    ListBenefits,
    Item,
    ContainerBenefits,
    ItemTitle,
    TitleBenefits,
    DescriptionBenefits,
    WhyContainer,
    ItemSubtitle
} from './Benefits.style';

export function Benefits() {
    const {
        images: {
            careers: {
                benefits: { image }
            }
        }
    } = useQueryContext();

    return (
        <>
            <WhyContainer>
                <WhySection>
                    <TitleWhy>{benefits.whySensidevTitle}</TitleWhy>
                    <DescriptionWhy>{benefits.whySensidevDescription}</DescriptionWhy>
                </WhySection>
            </WhyContainer>
            <BackgroundImage
                mobile={image.mobile}
                tablet={image.tablet}
                desktop={image.desktop}
                alt={benefits.alt}
            >
                <ListBenefits>
                    <TitleBenefits>{benefits.benefitsListTitle}</TitleBenefits>
                    <DescriptionBenefits>{benefits.benefitsListDescription}</DescriptionBenefits>
                    <ContainerBenefits>
                        {benefits.values.map((item) => {
                            const ItemIcon = require(`../../../../static/images/benefits/${item.icon}`);
                            return (
                                <Item key={`benefit-item-${item.title}`}>
                                    <ItemIcon />
                                    <ItemTitle>{item.title}</ItemTitle>
                                    <ItemSubtitle>{item.subtitle}</ItemSubtitle>
                                    <p>{item.text}</p>
                                </Item>
                            );
                        })}
                    </ContainerBenefits>
                </ListBenefits>
            </BackgroundImage>
        </>
    );
}
